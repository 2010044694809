<template>
    <SidebarGroup name="Manage Team" prepend-icon-name="list-thick" v-if="!teamIsBase">
        <template #activator> Manage Team </template>

        <template #icon-left="{ isActive }">
            <VIcon decorative :name="isActive ? 'team-filled' : 'team-thick'" />
        </template>

        <SidebarItem name="Team Profile" sub-item :to="{ name: 'team.profile' }" v-if="canAccessTeamSettings">
            Team Profile
        </SidebarItem>

        <SidebarItem name="Team Members" sub-item :to="{ name: 'team.members' }"> Team Members </SidebarItem>

        <SidebarItem
            :disabled="!canManageSubscription"
            name="Subscription"
            sub-item
            :to="{ name: 'team.subscription' }"
            v-tooltip="!canManageSubscription ? 'Only the team owner or admins can manage subscriptions' : null"
        >
            Subscription
        </SidebarItem>

        <SidebarItem
            name="Client Activity"
            sub-item
            :to="{ name: 'team.client-activity' }"
            v-if="teamIsManualBilling && canAccessClientActivity"
        >
            Client Activity
        </SidebarItem>

        <SidebarItem name="Reset Team" sub-item @click="showResetModal = true" v-if="canResetTeam">
            Reset Team
        </SidebarItem>

        <ConfirmationModal
            confirm-button-text="Reset Team"
            danger
            name="Reset Team"
            @confirm="onConfirmReset"
            v-model="showResetModal"
        >
            <template>
                Are you sure you want to reset your team? This will remove all Flows, Flow Templates, Tags etc.
            </template>

            This action cannot be undone.
        </ConfirmationModal>
    </SidebarGroup>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import * as PropTypes from 'vue-types';
import axios from 'axios';

import { SidebarGroup, SidebarItem } from '@/layout/Sidebar';
import UserPermissions from '@/enums/UserPermissions';
import useXavierGlobals from '@/hooks/useXavierGlobals';
import { hasPermission } from '@/utils/permissions';
import type { CurrentTeam } from '@/store/modules/teams/types/Team';
import ConfirmationModal from '@/components/ConfirmationModal';

export default defineComponent({
    name: 'TeamSidebarGroupTeamManagement',

    props: {
        currentTeam: PropTypes.object<CurrentTeam>().isRequired,
    },

    components: { SidebarGroup, SidebarItem, ConfirmationModal },

    methods: {
        async onConfirmReset() {
            this.showResetModal = false;

            await axios.post('/reset-test-team');

            window.location.reload();
        },
    },

    setup() {
        const canAccessClientActivity = hasPermission(UserPermissions.ACCESS_CLIENT_ACTIVITY);
        const canAccessTeamSettings = hasPermission(UserPermissions.ACCESS_TEAM_SETTINGS);
        const canManageSubscription = hasPermission(UserPermissions.MANAGE_SUBSCRIPTION);
        const { teamIsBase, teamIsManualBilling, currentTeam } = useXavierGlobals();
        const showResetModal = ref(false);
        const canResetTeam = currentTeam.isTestTeam;

        return {
            canAccessClientActivity,
            canAccessTeamSettings,
            canManageSubscription,
            teamIsBase,
            teamIsManualBilling,
            showResetModal,
            canResetTeam,
        };
    },
});
</script>

<style lang="scss" scoped></style>
