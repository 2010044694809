import type { RouteConfig } from 'vue-router';

import { extractClientIdParam } from '../utils';

const dataHealthRoutes: RouteConfig[] = [
    {
        component: () => import('@/scenes/ControlAccounts/ControlAccountsScene.vue'),
        meta: { disableLegacyStyles: true },
        name: 'client.insight.controlAccounts.dextified',
        path: 'health/control-accounts',
        props: extractClientIdParam,
    },
    {
        component: () => import('@/scenes/BankReconciliation/BankReconciliationSceneContainer.vue'),
        meta: { disableLegacyStyles: true },
        name: 'client.cleanup.bankReconciliation.unified',
        path: 'health/bank-reconciliation',
        props: extractClientIdParam,
    },
    {
        component: () => import('@/scenes/UnreconciledTransactions/UnreconciledTransactionsSceneContainer.vue'),
        meta: { disableLegacyStyles: true },
        name: 'client.cleanup.unreconciledTransactions.unified',
        path: 'health/unreconciled-transactions',
        props: extractClientIdParam,
    },
    {
        component: () => import('@/scenes/DuplicateContacts/DuplicateContactsSceneContainer.vue'),
        meta: { disableLegacyStyles: true },
        name: 'client.cleanup.duplicateContacts',
        path: 'health/duplicate-contacts',
        props: extractClientIdParam,
    },
    {
        component: () => import('@/scenes/MultiCodedContacts/MultiCodedContactsScene.vue'),
        meta: { disableLegacyStyles: true },
        name: 'client.cleanup.multi-codedContacts.dextified',
        path: 'health/multi-coded-contacts',
        props: extractClientIdParam,
    },
    {
        component: () => import('@/scenes/ContactDefaults/ContactDefaultsScene.vue'),
        meta: { disableLegacyStyles: true },
        name: 'client.cleanup.contactDefaults.dextified',
        path: 'health/contact-defaults',
        props: extractClientIdParam,
    },
    {
        component: () => import('@/scenes/DormantAccounts/DormantAccountsScene.vue'),
        meta: { disableLegacyStyles: true },
        name: 'client.cleanup.dormantAccounts.dextified',
        path: 'health/dormant-accounts',
        props: extractClientIdParam,
    },
    {
        component: () => import('@/scenes/DuplicateTransactions/DuplicateTransactionsScene.vue'),
        meta: { disableLegacyStyles: true },
        name: 'client.cleanup.duplicateTransactions.unified',
        path: 'health/duplicate-transactions',
        props: extractClientIdParam,
    },
    {
        component: () => import('@/scenes/FixedAssets/FixedAssetsScene.vue'),
        meta: { disableLegacyStyles: true },
        name: 'client.insight.fixedAssets.unified',
        path: 'health/fixed-assets',
        props: extractClientIdParam,
    },
    {
        component: () => import('@/scenes/LockDateHistory/LockDateHistoryScene.vue'),
        meta: { disableLegacyStyles: true },
        name: 'client.insight.lockDates.dextified',
        path: 'health/lock-dates',
        props: extractClientIdParam,
    },
    {
        component: () => import('@/scenes/Insights/HistoricalChanges/HistoricalChangesSceneContainer.vue'),
        meta: { disableLegacyStyles: true },
        name: 'client.insight.historicalChanges.dextified',
        path: 'health/historical-changes',
        props: extractClientIdParam,
    },
    {
        component: () => import('@/scenes/Payroll/PayrollScene.vue'),
        meta: { disableLegacyStyles: true },
        name: 'client.insight.payroll.unified',
        path: 'health/payroll',
        props: extractClientIdParam,
    },
];

export { dataHealthRoutes };
