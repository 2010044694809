import { Feature } from '@/enums/Feature';
import { customReportsRoutesUnified } from '@/router/team/custom-reports.routes';
import { practiceDashboardsRoutes } from '@/router/team/practiceDashboards.routes';
import useIsFeatureEnabled from '@/store/modules/feature/hooks/useIsFeatureEnabled';

import type { RouteConfig } from 'vue-router';

import { clientsRoutes } from './clients';
import { customReportRoutes } from './customReport';
import { focusRoutes } from './focus';
import { insightsDashboardRoutes } from './insightsDashboard';
import { workflowsRoutes } from './workflows.routes';

const hasProductTourEnabled = useIsFeatureEnabled(Feature.PRODUCT_TOUR);

const teamRoutes: RouteConfig[] = [
    {
        component: () => import('@/scenes/ClientList/ClientListSceneContainer_LEGACY.vue'),
        name: 'team.home',
        path: '',
    },
    ...clientsRoutes,
    ...customReportRoutes,
    ...customReportsRoutesUnified,
    ...focusRoutes,
    ...insightsDashboardRoutes,
    ...workflowsRoutes,
    ...practiceDashboardsRoutes,
    {
        component: () => import('@/scenes/PracticeActivityStats/PracticeActivityStatsSceneContainer.vue'),
        name: 'team.activityStats',
        path: 'activity-stats',
    },
    {
        component: () => import('@/scenes/PracticeDashboard/PracticeDashboardSceneContainer.vue'),
        name: 'team.dashboard',
        path: 'dashboard',
    },
    {
        component: () => import('@/scenes/DeadlinesDashboard/DeadlinesDashboardSceneContainer.vue'),
        name: 'team.deadlines',
        path: 'deadlines',
    },
    {
        component: () =>
            hasProductTourEnabled
                ? import('@/scenes/ProductTour/ProductTourSceneContainer.vue')
                : import('@/scenes/GetStarted/GetStartedScene.vue'),
        name: 'team.getStarted',
        path: 'get-started',
    },
    {
        component: () => import('@/scenes/Integrations_LEGACY/IntegrationsScene.vue'),
        name: 'team.integrations',
        path: 'integrations',
    },
    {
        component: () => import('@/scenes/Integrations/IntegrationsScene.vue'),
        name: 'team.integrations.unified',
        path: 'integrations-unified',
    },
    {
        component: () => import('@/scenes/FocusDailyDigest/FocusDailyDigestScene.vue'),
        name: 'team.focusDailyDigest',
        path: 'focus-daily-digest',
    },
    {
        component: () => import('@/scenes/NotificationSettings/NotificationSettingsSceneContainer.vue'),
        meta: { disableLegacyStyles: true },
        name: 'team.notificationSettings',
        path: 'notification-settings',
    },
    {
        component: () => import('@/scenes/ApiSettings/ApiSettingsScene.vue'),
        name: 'team.apiSettings',
        path: 'api-settings',
    },
    {
        component: () => import('@/scenes/LockDates/LockDatesScene.vue'),
        name: 'team.lockDates',
        path: 'lock-dates',
    },
    {
        component: () => import('@/scenes/TeamMembers/TeamMembersScene.vue'),
        name: 'team.members',
        path: 'members',
    },
    {
        component: () => import('@/scenes/MtdDashboard/MtdDashboardSceneContainer.vue'),
        name: 'team.mtdDashboard',
        path: 'mtd-dashboard',
    },

    {
        component: () => import('@/scenes/TeamProfile/TeamProfileScene.vue'),
        name: 'team.profile',
        path: 'profile',
    },
    {
        component: () => import('@/scenes/ReportList/ReportListScene.vue'),
        name: 'team.reports',
        path: 'reports',
        props: true,
    },
    {
        component: () => import('@/scenes/SelectCodes/SelectCodesScene.vue'),
        name: 'team.selectCodes',
        path: 'select-codes',
    },
    {
        component: () => import('@/scenes/TeamSubscription/TeamSubscriptionScene.vue'),
        name: 'team.subscription',
        path: 'subscription',
    },
    {
        component: () => import('@/scenes/TeamSubscription/TeamSubscriptionScene.vue'),
        name: 'team.subscription',
        path: 'team/subscription',
    },
    {
        component: () => import('@/scenes/TeamClientActivity/TeamClientActivityScene.vue'),
        name: 'team.client-activity',
        path: 'team/client-activity',
    },
    {
        component: () => import('@/scenes/Tags/TagsScene.vue'),
        name: 'team.tags',
        path: 'tags',
    },
    {
        component: () => import('@/scenes/UnintegratedImport/UnintegratedImportScene.vue'),
        name: 'team.unintegrated-import',
        path: 'unintegrated-import',
    },
    {
        component: () => import('@/scenes/UnintegratedImport/ReviewAndImport.vue'),
        name: 'team.unintegrated-import.staging',
        path: 'unintegrated-import/staging',
    },
    {
        component: () => import('@/scenes/CustomHealthScores/CustomHealthScoreSceneContainer.vue'),
        name: 'team.customHealthScores',
        path: 'custom-health-scores',

        children: [
            {
                component: () => import('@/scenes/CustomHealthScores/CustomHealthScoreScene.vue'),
                name: 'team.customHealthScores.view',
                path: '',
            },
            {
                component: () => import('@/scenes/CustomHealthScores/CustomHealthScoreEditScene.vue'),
                name: 'team.customHealthScores.edit',
                path: 'edit',
            },
            {
                component: () => import('@/scenes/CustomHealthScores/CustomHealthScoreReviewScene.vue'),
                name: 'team.customHealthScores.review',
                path: 'review',
            },
        ],
    },
];

export { teamRoutes };
