<template>
    <div class="deep-link" v-if="deepLink && deepLink !== `#` && isLink">
        <VButton :name="deepLinkName" text @click="onButtonClick" v-if="shouldShowModal">
            <template #icon-left>
                <VIcon decorative name="external-link-thick" :vertical-align="false" />
            </template>
            {{ deepLinkText }}
        </VButton>

        <VLink external :href="deepLink" :name="deepLinkName" new-tab v-if="!shouldShowModal">
            {{ deepLinkText }}
        </VLink>

        <Modal name="deep-link-modal" @confirm="onProceed" v-model="isVisible">
            <ModalHeader>
                <div>
                    <img alt="Alert!" class="modal-icon" src="/img/icons/alert-icon.svg" />
                    View in QuickBooks Online
                </div>
            </ModalHeader>
            <ModalContent>
                Please make sure you are logged in to {{ clientName }}'s file in QuickBooks before proceeding, so we can
                take you to the right place.
            </ModalContent>
            <ModalFooter confirm-button-text="Proceed">
                <template #left v-if="loginLink && loginLink !== `#`">
                    <VLink external :href="loginLink" :name="loginLinkName" new-tab> Log in to QuickBooks </VLink>
                </template>
            </ModalFooter>
        </Modal>
    </div>
    <p class="grid-no-link" name="no-link" v-else-if="!isLink">{{ deepLinkText }}</p>
</template>

<script>
import Vue from 'vue';
import PropTypes from 'vue-types';
import { mapActions, mapGetters } from 'vuex';

import Modal, { ModalHeader, ModalContent, ModalFooter } from '@/components/Modal';
import { useProviderUrl } from '@/composables/useProviderUrl';
import useXavierGlobals from '@/hooks/useXavierGlobals';
import linkText from '@/utils/linkText';

export default Vue.extend({
    name: 'DeepLink',

    components: {
        Modal,
        ModalHeader,
        ModalContent,
        ModalFooter,
    },

    props: {
        /**
         * Target URL for the Deep Link.
         *
         * @param {String} href
         */
        href: PropTypes.string,

        /**
         * Text for the Deep Link.
         *
         * @param {String} linkText
         */
        linkText: PropTypes.string,
    },

    data() {
        return {
            isVisible: false,
        };
    },

    computed: {
        ...mapGetters({
            client: 'legacyClients/currentClient',
            qboClientsClicked: 'session/qboClientsClicked',
        }),

        /**
         * Checks for non-links, which are present in some grids
         * (e.g. in customer/supplier reliance checks).
         *
         * @returns Boolean
         */
        isLink() {
            return !this.params?.data?.isOther ?? true;
        },

        /**
         * Generates the name for the Deep Link.
         *
         * @returns String
         */
        deepLinkName() {
            return this.client.provider + '-deep-link';
        },

        /**
         * Gets the text for the Deep Link.
         *
         * @returns String
         */
        deepLinkText() {
            if (this.linkText) {
                return this.linkText;
            }

            if (this.params?.linkText) {
                return this.params.linkText;
            }

            return linkText(this.client, this.isLoginLink);
        },

        /**
         * Gets the target URL for the Deep Link.
         *
         * @returns String
         */
        deepLink() {
            const practiceCrn = useXavierGlobals().currentTeam.rbExternalId;

            if (this.href) {
                return useProviderUrl(this.client, practiceCrn, {
                    feature: '',
                    subFeature: '',
                    url: this.href,
                });
            }

            if (this.params?.value) {
                return useProviderUrl(this.client, practiceCrn, {
                    feature: this.params?.feature ?? '',
                    subFeature: this.params?.subFeature ?? '',
                    url: this.params?.value ?? '',
                });
            }

            return null;
        },

        /**
         * Checks if the target URL is a login link.
         *
         * @returns Boolean
         */
        isLoginLink() {
            return this.deepLink.startsWith('https://qbo.intuit.com/login');
        },

        /**
         * Generates the login link for the client clicked.
         *
         * @returns String
         */
        loginLink() {
            return 'https://qbo.intuit.com/login?deeplinkcompanyid=' + this.realmId;
        },

        /**
         * Generates the name for the login link.
         *
         * @returns String
         */
        loginLinkName() {
            return this.client.provider + '-login-link';
        },

        /**
         * Gets the query string from the target URL.
         *
         * @returns URLSearchParams
         */
        urlSearchParams() {
            const queryString = this.deepLink?.split('?')[1];

            return queryString ? new URLSearchParams(queryString) : null;
        },

        /**
         * Gets the realm ID from the query string.
         *
         * @returns String
         */
        realmId() {
            return this.urlSearchParams?.get('deeplinkcompanyid') ?? null;
        },

        /**
         * Gets the client name.
         *
         * @returns String
         */
        clientName() {
            return this.client.name;
        },

        /**
         * Checks if the client is a QBO client.
         *
         * @returns Boolean
         */
        isQuickBooksClient() {
            return this.client.provider === 'quickbooks';
        },

        /**
         * Generates the text for the tooltip.
         * Tooltip removed due to bug XM-2149, but may be reintroduced.
         *
         * @returns String
         */
        tooltipText() {
            if (this.isQuickBooksClient && !this.isLoginLink) {
                return `
                    Please make sure you are logged in to ${this.clientName}'s file in QuickBooks before proceeding, so we
                    can take you to the right place.
                `;
            }

            return null;
        },

        /**
         * Checks if the modal should be shown. The modal is shown only for the first
         * click of each QBO client, within a session, and not shown for login links.
         *
         * @returns Boolean
         */
        shouldShowModal() {
            return this.isQuickBooksClient && !this.isLoginLink && !this.qboClientsClicked.includes(this.realmId);
        },
    },

    methods: {
        ...mapActions({
            setQboClientsClicked: 'session/setQboClientsClicked',
        }),
        /**
         * Adds the realm ID of the QBO client clicked to an array in
         * session storage, and triggers the modal to be displayed.
         */
        onButtonClick() {
            this.setQboClientsClicked(this.realmId);
            this.isVisible = true;
        },

        /**
         * Opens the target URL in a new tab.
         */
        onProceed() {
            window.open(this.deepLink, '_blank');
        },
    },
});
</script>

<style lang="scss" scoped>
.modal-icon {
    height: 32px;
    margin-right: 8px;
    width: 32px;
}

.grid-no-link {
    margin: 0;
}

.deep-link {
    display: inline-flex;
}
</style>
