import type { RouteConfig } from 'vue-router';

const workflowsRoutes: RouteConfig[] = [
    {
        component: { template: '<router-view />' },
        meta: { disableLegacyStyles: true },
        path: 'workflows',

        children: [
            {
                component: () =>
                    import('@/scenes/Workflows/WorkflowsList').then((m) => m.TeamWorkflowsListSceneContainer),
                meta: { disableLegacyStyles: true },
                name: 'team.workflows.list',
                path: '/',
            },
            {
                component: () => import('@/scenes/Workflows/WorkflowCreator').then((m) => m.FlowCreatorScene),
                meta: { disableLegacyStyles: true },
                name: 'team.workflows.create',
                path: 'create',
            },
            {
                component: () => import('@/scenes/Workflows').then((m) => m.CustomChecksSceneContainer),
                meta: { disableLegacyStyles: true },
                name: 'team.workflows.custom-checks.list',
                path: 'custom-checks',
            },
            {
                component: () => import('@/scenes/Workflows').then((m) => m.DraftWorkflowsSceneContainer),
                meta: { disableLegacyStyles: true },
                name: 'team.workflows.drafts.list',
                path: 'drafts',
            },
            {
                component: () => import('@/scenes/Workflows').then((m) => m.WorkflowTemplatesSceneContainer),

                meta: { disableLegacyStyles: true },
                name: 'team.workflows.templates.list',
                path: 'templates',
            },
            {
                component: () => import('@/scenes/Workflows').then((m) => m.WorkflowsRepeatingListSceneContainer),
                meta: { disableLegacyStyles: true },
                name: 'team.workflows.repeating.list',
                path: 'repeating',
            },
        ],
    },
];

export { workflowsRoutes };
